
import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    ref,
    toRef,
    watch
} from "vue";
import QRCode from "qrcode";
import useIframe from "@/composables/useIframe";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import AppIcon from "@/components/common/AppIcon.vue";
import AppModal from "@/components/common/AppModal.vue";
import AppLoader from "@/components/common/AppLoader.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "MailPreviewContent",
    props: {
        html: { type: String, default: "" }
    },
    components: {
        AppIcon,
        AppModal,
        AppLoader
    },
    setup(props) {
        const store = useStore();
        const { setIframeSize } = useIframe();
        const toast = useToast();
        const previewLoaded = ref(false);
        const html = toRef(props, "html");
        const url = computed(
            () =>
                `${store.state.auth.baseUrl}/email/preview/${store.state.mails.active.id}`
        );
        const { t } = useI18n();

        const reloadPreview = async () => {
            if (html.value.length) {
                await nextTick(() => {
                    const iframe: HTMLIFrameElement | null = document.getElementById(
                        "emailPreview"
                    ) as HTMLIFrameElement;
                    const wrapper = document.getElementById(
                        "emailPreviewWrapper"
                    );
                    if (iframe && wrapper) {
                        iframe.onload = () => {
                            setIframeSize(iframe, wrapper);
                        };
                    }
                });
            }
        };

        const openPreviewInNewWindow = () => {
            window.open(url.value, "_blank");
        };

        const copyStringToClipboard = (str: string) => {
            const el = document.createElement("textarea");
            el.value = str;
            el.setAttribute("readonly", "");
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        };

        const copyToClipboard = async () => {
            try {
                await copyStringToClipboard(url.value);
                toast.add({
                    severity: "success",
                    summary: t("components.mails.previewContent.urlCopied"),
                    detail: `${url.value}`,
                    life: 3000
                });
            } catch (e) {
                toast.add({
                    severity: "error",
                    summary: "Copy to clipboard",
                    detail: "Something went wrong",
                    life: 3000
                });
            }
        };

        const qrCode = ref("");
        const qrcodeModalOpen = ref(false);

        const generateQR = async () => {
            try {
                qrCode.value = await QRCode.toDataURL(url.value);
            } catch (err) {
                console.error(err);
            }
        };

        const openQRCodeModal = async () => {
            await generateQR();
            qrcodeModalOpen.value = true;
        };

        watch(html, async () => {
            previewLoaded.value = false;
            await reloadPreview();
            previewLoaded.value = true;
        });

        onMounted(() => {
            reloadPreview();
            previewLoaded.value = true;
        });

        return {
            url,
            qrCode,
            qrcodeModalOpen,
            previewLoaded,
            openPreviewInNewWindow,
            copyToClipboard,
            openQRCodeModal
        };
    }
});
