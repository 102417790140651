import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start text-sm font-bold leading-6 py-2 w-72" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center py-1 ml-6"
}
const _hoisted_4 = ["data-test"]
const _hoisted_5 = {
  key: 0,
  class: "border-l border-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-wrap md:flex-nowrap justify-between items-start bg-white px-5 py-6 rounded-md hover:shadow-xl border hover:border-light-3", [
            _ctx.disabled ? 'opacity-40' : '',
            _ctx.active ? 'shadow-xl border-light-3' : 'shadow-lg border-white'
        ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.status === _ctx.Status.success)
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 0,
            name: "CheckCircle",
            class: "text-success mr-3"
          }))
        : _createCommentVNode("", true),
      (_ctx.status === _ctx.Status.muted)
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 1,
            name: "CheckCircle",
            class: "text-light-3 mr-3"
          }))
        : _createCommentVNode("", true),
      (_ctx.status === _ctx.Status.danger)
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 2,
            name: "ExclamationCircle",
            class: "text-danger mr-3"
          }))
        : _createCommentVNode("", true),
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.showActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(["flex justify-center w-20 text-sm py-1 leading-6 bg-light-2 border border-light-2 hover:bg-light-3 hover:border-light-3", [
                    _ctx.contextMenu ? 'rounded-l-md' : 'rounded-md',
                    _ctx.actionDisabled ? 'opacity-40 select-none' : 'cursor-pointer'
                ]]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitAction(_ctx.action))),
            "data-test": _ctx.action
          }, _toDisplayString(_ctx.$t("general." + _ctx.action)), 11, _hoisted_4),
          (_ctx.contextMenu)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _renderSlot(_ctx.$slots, "contextmenu")
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}