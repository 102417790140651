
import { computed, defineComponent } from "vue";
import AppSection from "@/components/common/AppSection.vue";
import CardGroup from "@/components/card/CardGroup.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import CardKpi from "@/components/card/CardKpi.vue";
import CardAutomation from "@/components/card/CardAutomation.vue";
import DropdownMenu from "@/components/common/dropdown/DropdownMenu.vue";
import DropdownMenuItem from "@/components/common/dropdown/DropdownMenuItem.vue";
import { useStore } from "vuex";
import store from "@/store";
import MailModel from "@/models/MailModel";
import useKPIs from "@/composables/useKPIs";

export default defineComponent({
    name: "AutomationsOverviewPage",
    components: {
        AppSection,
        AppIcon,
        CardGroup,
        CardKpi,
        CardAutomation,
        DropdownMenu,
        DropdownMenuItem
    },
    async beforeRouteEnter(to, from, next) {
        if (store.state.automations.total > 0) {
            store.dispatch("automations/fetchList").finally();
        } else {
            store.commit("app/SET_LOADING", true);
            await store.dispatch("automations/fetchList").then(() => {
                store.commit("app/SET_LOADING", false);
            });
        }
        next();
    },
    setup() {
        const store = useStore();
        const { kpiDays, kpis, kpisLoading } = useKPIs();

        const enabledAutomations = computed(
            (): MailModel => store.getters["automations/enabledList"]
        );
        const disabledAutomations = computed(
            (): MailModel => store.getters["automations/disabledList"]
        );

        const currency = computed(() => store.state.store.currency);

        return {
            enabledAutomations,
            disabledAutomations,
            kpiDays,
            kpis,
            kpisLoading,
            currency
        };
    }
});
