
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppToggle",
    emits: ["update:active"],
    props: {
        active: {
            type: Boolean,
            default: false
        }
    }
});
