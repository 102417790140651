
import { computed, defineComponent, ref } from "vue";
import AppSection from "@/components/common/AppSection.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppInput from "@/components/common/AppInput.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import AppToggle from "@/components/common/AppToggle.vue";
import AppRadioButton from "@/components/common/AppRadioButton.vue";
import CardGroup from "@/components/card/CardGroup.vue";
import CardListItem from "@/components/card/CardListItem.vue";
import store from "@/store";
import { useStore } from "vuex";
import AutomationModel from "@/models/AutomationModel";
import { useRouter } from "vue-router";
import { SelectOptionInterface } from "@/types";
import EmailAddressModel from "@/models/EmailAddressModel";
import MailModel from "@/models/MailModel";
import { useI18n } from "vue-i18n";
import moment from "moment";
import BeeEditor from "@/components/editor/BeeEditor.vue";
import MailPreviewContent from "@/components/mails/MailPreviewContent.vue";
import TemplateModel from "@/models/TemplateModel";
import { openDialog } from "vue3-promise-dialog";
import ChooseTemplateModal from "@/components/editor/ChooseTemplateModal.vue";
import useUnicodeHelper from "@/composables/useUnicodeHelper";
import useSendTestMail from "@/composables/useSendTestMail";

export default defineComponent({
    name: "AutomationActivationPage",
    components: {
        MailPreviewContent,
        BeeEditor,
        AppSection,
        AppButton,
        AppIcon,
        AppInput,
        AppSelect,
        AppToggle,
        AppRadioButton,
        CardGroup,
        CardListItem
    },
    async beforeRouteEnter(to, from, next) {
        if (!store.state.auth.beeEditorUid) {
            await store.dispatch("auth/fetchClientConfig");
        }

        if (!store.state.buildertokens.is_fetched) {
            store.dispatch("buildertokens/fetchState").finally();
        }

        if (!store.state.automations.active) {
            const automationInList = store.getters["automations/getById"](
                parseInt(to.params.id as string)
            );

            if (automationInList !== undefined) {
                store.commit("automations/SET_ACTIVE", automationInList);
            } else {
                store.commit("app/SET_LOADING", true);
                await store.dispatch("automations/fetchActive", to.params.id);
                store.commit("app/SET_LOADING", false);
            }
        }

        if (store.state.automations.active) {
            await store.dispatch(
                "mails/fetchActive",
                (store.state.automations.active.email as MailModel).id
            );
        }
        next();
    },
    setup() {
        const store = useStore(),
            router = useRouter(),
            { t } = useI18n(),
            { encodeUnicode, decodeUnicode } = useUnicodeHelper(),
            { sendTestMail } = useSendTestMail(),
            { automations, mails } = store.state,
            activeItem = ref(""),
            isSaving = ref(false),
            automation = computed((): AutomationModel => automations.active),
            enabled = computed(
                (): boolean => automations.active.status === "enabled"
            ),
            activeSince = computed(() => {
                return automations.active.status === "enabled"
                    ? moment(automations.active.dateEnabled).format(
                          "DD.MM.yyyy"
                      )
                    : "";
            });

        // Automation actions
        const waitPeriod = ref(
                automations.active.waitPeriod &&
                    automations.active.waitPeriod > 23
                    ? "days"
                    : "hours"
            ),
            waitQuantityData = ref(automations.active.waitPeriod || 2),
            waitQuantity = ref(automations.active.waitPeriod || 2),
            waitOptionsHours = [
                { label: `1 ${t("general.hour")}`, value: 1 },
                { label: `2 ${t("general.hours")}`, value: 2 },
                { label: `3 ${t("general.hours")}`, value: 3 },
                { label: `4 ${t("general.hours")}`, value: 4 },
                { label: `5 ${t("general.hours")}`, value: 5 },
                { label: `6 ${t("general.hours")}`, value: 6 },
                { label: `7 ${t("general.hours")}`, value: 7 },
                { label: `8 ${t("general.hours")}`, value: 8 },
                { label: `9 ${t("general.hours")}`, value: 9 },
                { label: `10 ${t("general.hours")}`, value: 10 },
                { label: `11 ${t("general.hours")}`, value: 11 },
                { label: `12 ${t("general.hours")}`, value: 12 },
                { label: `13 ${t("general.hours")}`, value: 13 },
                { label: `14 ${t("general.hours")}`, value: 14 },
                { label: `15 ${t("general.hours")}`, value: 15 },
                { label: `16 ${t("general.hours")}`, value: 16 },
                { label: `17 ${t("general.hours")}`, value: 17 },
                { label: `18 ${t("general.hours")}`, value: 18 },
                { label: `19 ${t("general.hours")}`, value: 19 },
                { label: `20 ${t("general.hours")}`, value: 20 },
                { label: `21 ${t("general.hours")}`, value: 21 },
                { label: `22 ${t("general.hours")}`, value: 22 },
                { label: `23 ${t("general.hours")}`, value: 23 }
            ],
            waitOptionsDays = [
                { label: `1 ${t("general.day")}`, value: 24 },
                { label: `2 ${t("general.days")}`, value: 48 },
                { label: `3 ${t("general.days")}`, value: 72 },
                { label: `4 ${t("general.days")}`, value: 96 },
                { label: `5 ${t("general.days")}`, value: 120 },
                { label: `6 ${t("general.days")}`, value: 144 },
                { label: `7 ${t("general.days")}`, value: 168 },
                { label: `14 ${t("general.days")}`, value: 336 },
                { label: `30 ${t("general.days")}`, value: 720 },
                { label: `90 ${t("general.days")}`, value: 2160 },
                { label: `180 ${t("general.days")}`, value: 4320 }
            ],
            waitOptions = computed(() =>
                waitPeriod.value === "hours"
                    ? waitOptionsHours
                    : waitOptionsDays
            ),
            waitPeriodLabel = computed(() => {
                if (waitQuantity.value === 1) {
                    return t("general.hour");
                } else if (waitQuantity.value < 24) {
                    return t("general.hours");
                } else if (waitQuantity.value === 24) {
                    return t("general.day");
                } else {
                    return t("general.days");
                }
            }),
            setWaitQuantityData = () => {
                if (waitPeriod.value === "hours") {
                    waitQuantityData.value = 2;
                } else {
                    waitQuantityData.value = 48;
                }
            },
            setWaitPeriod = () => {
                if (waitQuantity.value < 24) {
                    waitPeriod.value = "hours";
                } else {
                    waitPeriod.value = "days";
                }
            },
            saveWaitQuantity = () => {
                activeItem.value = "";
                waitQuantity.value = waitQuantityData.value;
                setWaitPeriod();
            },
            cancelWaitQuantity = () => {
                activeItem.value = "";
                waitQuantityData.value = waitQuantity.value;
                setWaitPeriod();
            };

        // Sender
        const fromName = ref(
                mails.active.fromName ? mails.active.fromName : ""
            ),
            fromAddress = ref(
                mails.active.fromAddress ? mails.active.fromAddress : ""
            ),
            replyToAddress = ref(
                mails.active.replyToAddress ? mails.active.replyToAddress : ""
            ),
            emailOptions = computed((): SelectOptionInterface[] => {
                return mails.addressList.map((address: EmailAddressModel) => ({
                    label: address.mailer_from_email,
                    value: address.mailer_from_email
                }));
            });

        const defaultEmailAddress:
            | EmailAddressModel
            | undefined = mails.addressList.find(
            (address: EmailAddressModel) => {
                return address.default;
            }
        );

        if (fromAddress.value === "" && defaultEmailAddress !== undefined) {
            fromAddress.value = defaultEmailAddress.mailer_from_email;
            fromName.value = defaultEmailAddress.mailer_from_name;
            replyToAddress.value = defaultEmailAddress.mailer_reply_to;
        }

        // Subject
        const subject = ref(
                mails.active.subject
                    ? mails.active.subject !== "Blank"
                        ? mails.active.subject
                        : ""
                    : ""
            ),
            subjectStatus = computed((): string => {
                if (!subject.value.length) {
                    return "danger";
                }
                return activeItem.value === "subject" ? "muted" : "success";
            }),
            preheaderText = ref(
                mails.active.preheaderText ? mails.active.preheaderText : ""
            );

        const subjectInputStatus = computed(() => {
                if (subject.value.length < 100) {
                    return "";
                }
                return "error";
            }),
            subjectInputDescription = computed(() => {
                if (subjectInputStatus.value === "error") {
                    return t("pages.mail.subjectInputError");
                }
                return "";
            }),
            preheaderTextInputStatus = computed(() => {
                if (
                    preheaderText.value.length > 0 &&
                    preheaderText.value.length < 85
                ) {
                    return "warning";
                }
                if (preheaderText.value.length > 100) {
                    return "error";
                }
                return "";
            }),
            preheaderTextInputDescription = computed(() => {
                if (preheaderTextInputStatus.value === "warning") {
                    return t("pages.mail.preheaderInputWarning");
                }
                if (preheaderTextInputStatus.value === "error") {
                    return t("pages.mail.preheaderInputError");
                }
                return t("pages.mail.preheaderTextText");
            });

        //Content
        const name = ref(mails.active.name),
            template = ref(
                mails.active.beeTemplate ? mails.active.beeTemplate : null
            ),
            html = ref(mails.active.customHtml || "");

        // UTM Tag
        const utmCampaign = ref(
                mails.active.utmTags !== undefined
                    ? mails.active.utmTags.utmCampaign || ""
                    : ""
            ),
            utmContent = ref(
                mails.active.utmTags !== undefined
                    ? mails.active.utmTags.utmContent || ""
                    : ""
            ),
            utmMedium = ref(
                mails.active.utmTags !== undefined
                    ? mails.active.utmTags.utmMedium || ""
                    : ""
            ),
            utmSource = ref(
                mails.active.utmTags !== undefined
                    ? mails.active.utmTags.utmSource || ""
                    : ""
            );

        const canActivate = computed(
            (): boolean =>
                html.value.length &&
                mails.active.subject.length &&
                mails.active.subject !== "Blank"
        );

        const goToOverview = async () => {
                await router.push({ name: "automations-overview" });
            },
            activateAutomation = async () => {
                isSaving.value = true;
                await store.dispatch("automations/activate", {
                    id: automations.active.id,
                    data: {
                        status: "enabled",
                        wait_period: waitQuantity.value
                    }
                });
                window.analytics.track("Automation Enabled", {
                    userId: store.getters["user/emailHash"],
                    automationType: automations.active.type
                });
                isSaving.value = false;
                await goToOverview();
            },
            deactivateAutomation = async () => {
                isSaving.value = true;
                await store.dispatch("automations/activate", {
                    id: automations.active.id,
                    data: {
                        status: "disabled"
                    }
                });
                window.analytics.track("Automation Disabled", {
                    userId: store.getters["user/emailHash"],
                    automationType: automations.active.type
                });
                isSaving.value = false;
                await goToOverview();
            },
            saveEmail = async () => {
                try {
                    isSaving.value = true;
                    const mailData = {
                        ...mails.active,
                        name: name.value,
                        fromName: fromName.value,
                        fromAddress: fromAddress.value,
                        subject: subject.value || "Blank",
                        preheaderText: preheaderText.value,
                        beeTemplate: template.value,
                        customHtml: html.value || "",
                        utmTags: {
                            utmCampaign: utmCampaign.value,
                            utmContent: utmContent.value,
                            utmMedium: utmMedium.value,
                            utmSource: utmSource.value
                        },
                        category: mails.active.category?.id
                    };
                    const response = await store.dispatch(
                        "mails/editActive",
                        mailData
                    );
                    isSaving.value = false;
                    activeItem.value = "";
                    if (response.status === 201) {
                        await router.replace({
                            name: "mail-edit",
                            params: {
                                id: response.data.email.id
                            }
                        });
                    }
                } catch (error) {
                    //TODO: handleError
                    console.log("error", error);
                }
            },
            resetEmail = () => {
                activeItem.value = "";
                name.value = mails.active.name;
                fromName.value = mails.active.fromName || "";
                fromAddress.value = mails.active.fromAddress || "";
                if (
                    fromAddress.value === "" &&
                    defaultEmailAddress !== undefined
                ) {
                    fromAddress.value = defaultEmailAddress.mailer_from_email;
                    fromName.value = defaultEmailAddress.mailer_from_name;
                    replyToAddress.value = defaultEmailAddress.mailer_reply_to;
                }
                preheaderText.value = mails.active.preheaderText;
                subject.value = mails.active.subject
                    ? mails.active.subject !== "Blank"
                        ? mails.active.subject
                        : ""
                    : "";
                template.value = mails.active.beeTemplate
                    ? JSON.parse(atob(mails.active.beeTemplate))
                    : null;
                html.value = mails.active.customHtml || "";
                utmCampaign.value = mails.active.utmTags.utmCampaign || "";
                utmContent.value = mails.active.utmTags.utmContent || "";
                utmMedium.value = mails.active.utmTags.utmMedium || "";
                utmSource.value = mails.active.utmTags.utmSource || "";
            };

        const emailId = mails.active.id || 0;
        const templateType = ref("mail");
        const templateContent = ref("");
        const templateName = ref("");
        const templateKey = ref("");
        const editTemplate = async () => {
            if (template.value === null) {
                const chosenTemplate: TemplateModel = await openDialog(
                    ChooseTemplateModal
                );
                if (chosenTemplate !== null) {
                    templateContent.value = chosenTemplate.content;
                }
            } else {
                templateContent.value = JSON.parse(
                    decodeUnicode(template.value)
                );
            }

            templateName.value = name.value;
            templateKey.value = `mail_${mails.active.id}`;
        };

        const saveTemplateContent = async (payload: {
            templateContent: string;
            htmlContent: string;
            templateName: string;
        }) => {
            template.value = encodeUnicode(
                JSON.stringify(payload.templateContent)
            );
            html.value = payload.htmlContent;
            name.value = payload.templateName;
            await saveEmail();
        };

        const exitEditor = () => {
            templateContent.value = "";
        };

        return {
            name,
            automation,
            enabled,
            activeItem,
            activeSince,
            isSaving,
            waitPeriod,
            waitPeriodLabel,
            waitQuantity,
            waitQuantityData,
            waitOptions,
            fromName,
            fromAddress,
            emailOptions,
            subject,
            subjectStatus,
            preheaderText,
            subjectInputStatus,
            subjectInputDescription,
            preheaderTextInputStatus,
            preheaderTextInputDescription,
            template,
            html,
            utmCampaign,
            utmContent,
            utmMedium,
            utmSource,
            canActivate,
            saveWaitQuantity,
            cancelWaitQuantity,
            setWaitQuantityData,
            goToOverview,
            activateAutomation,
            deactivateAutomation,
            saveEmail,
            resetEmail,
            emailId,
            templateContent,
            templateName,
            templateType,
            templateKey,
            editTemplate,
            exitEditor,
            sendTestMail,
            saveTemplateContent
        };
    }
});
