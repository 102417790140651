
import AppToggle from "@/components/common/AppToggle.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import { useRouter } from "vue-router";
import { computed, defineComponent, PropType } from "vue";
import AutomationModel from "@/models/AutomationModel";
import { useStore } from "vuex";
import { StatsEmailModel } from "@/models/StatsModel";
import moment from "moment";

export default defineComponent({
    name: "CardAutomation",
    props: {
        automation: {
            type: Object as PropType<AutomationModel>,
            required: true
        }
    },
    components: {
        AppToggle,
        AppIcon
    },
    setup(props) {
        const router = useRouter(),
            store = useStore(),
            stats = computed((): StatsEmailModel | undefined =>
                store.getters["stats/getStatsByAutomationId"](
                    props.automation.id
                )
            );
        const activeSince = computed(() => {
            return moment(props.automation.dateEnabled).format("DD.MM.yyyy");
        });
        const goToAutomation = async () => {
                await router.push({
                    name: "automation-activation",
                    params: { id: props.automation.id }
                });
            },
            changeStatus = async (val: boolean) => {
                if (val) {
                    await goToAutomation();
                } else {
                    await store.dispatch("automations/activate", {
                        id: props.automation.id,
                        data: {
                            status: "disabled"
                        }
                    });
                    window.analytics.track("Automation Disabled", {
                        userId: store.getters["user/emailHash"],
                        automationType: props.automation.type
                    });
                    store.commit("automations/CLEAR_ACTIVE");
                }
            };
        const currency = computed(() => store.state.store.currency);
        return { stats, activeSince, changeStatus, goToAutomation, currency };
    }
});
