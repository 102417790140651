import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "leading-17 mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "leading-6 mb-6 md:w-176"
}
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-7" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-3 gap-7" }
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_6 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_menu_item = _resolveComponent("dropdown-menu-item")!
  const _component_dropdown_menu = _resolveComponent("dropdown-menu")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_card_kpi = _resolveComponent("card-kpi")!
  const _component_card_group = _resolveComponent("card-group")!
  const _component_app_section = _resolveComponent("app-section")!
  const _component_card_automation = _resolveComponent("card-automation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_section, { class: "pt-5 pb-8 bg-light-2" }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("pages.automations.header")), 1),
        (!_ctx.enabledAutomations.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("pages.automations.text")), 1))
          : _createCommentVNode("", true),
        (_ctx.enabledAutomations.length)
          ? (_openBlock(), _createBlock(_component_card_group, {
              key: 1,
              title: _ctx.$t('general.performance')
            }, {
              sorting: _withCtx(() => [
                _createVNode(_component_dropdown_menu, {
                  label: 
                        `${_ctx.$t('general.last')} ${_ctx.kpiDays} ${_ctx.$t('general.days')}`
                    
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_dropdown_menu_item, {
                      label: `${_ctx.$t('general.last')} 7 ${_ctx.$t('general.days')}`,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.kpiDays = 7))
                    }, null, 8, ["label"]),
                    _createVNode(_component_dropdown_menu_item, {
                      label: 
                            `${_ctx.$t('general.last')} 30 ${_ctx.$t('general.days')}`
                        ,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.kpiDays = 30))
                    }, null, 8, ["label"]),
                    _createVNode(_component_dropdown_menu_item, {
                      label: 
                            `${_ctx.$t('general.last')} 60 ${_ctx.$t('general.days')}`
                        ,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.kpiDays = 60))
                    }, null, 8, ["label"]),
                    _createVNode(_component_dropdown_menu_item, {
                      label: 
                            `${_ctx.$t('general.last')} 90 ${_ctx.$t('general.days')}`
                        ,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.kpiDays = 90))
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.totalMailsSent'),
                    value: _ctx.kpis.automation_mail_sent_total.value,
                    trend: _ctx.kpis.automation_mail_sent_total.trend,
                    loading: _ctx.kpisLoading
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "MailOpen" })
                    ]),
                    _: 1
                  }, 8, ["header", "value", "trend", "loading"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.numberOfConversions'),
                    value: _ctx.kpis.automation_conversion_total.value,
                    trend: _ctx.kpis.automation_conversion_total.trend,
                    loading: _ctx.kpisLoading
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "Conversion" })
                    ]),
                    _: 1
                  }, 8, ["header", "value", "trend", "loading"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.automations.totalRevenue'),
                    currency: _ctx.currency,
                    value: 
                        parseFloat(
                            _ctx.kpis.automation_revenue_total.value.toFixed(1)
                        )
                    ,
                    trend: _ctx.kpis.automation_revenue_total.trend,
                    loading: _ctx.kpisLoading
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "CurrencyDollar" })
                    ]),
                    _: 1
                  }, 8, ["header", "currency", "value", "trend", "loading"])
                ])
              ]),
              _: 1
            }, 8, ["title"]))
          : (_openBlock(), _createBlock(_component_card_group, {
              key: 2,
              title: 
                `${_ctx.$t('general.performance')} (${_ctx.$t(
                    'pages.automations.preview'
                )})`
            ,
              disabled: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.totalMailsSent'),
                    value: 451,
                    trend: 445,
                    disabled: true
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "MailOpen" })
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.numberOfConversions'),
                    value: 138,
                    trend: 125,
                    disabled: true
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "Conversion" })
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.automations.totalRevenue'),
                    currency: _ctx.currency,
                    value: 9800,
                    trend: 9600,
                    disabled: true
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "CurrencyDollar" })
                    ]),
                    _: 1
                  }, 8, ["header", "currency"])
                ])
              ]),
              _: 1
            }, 8, ["title"]))
      ]),
      _: 1
    }),
    (_ctx.enabledAutomations.length)
      ? (_openBlock(), _createBlock(_component_app_section, {
          key: 0,
          class: _normalizeClass(["pt-8 pb-8", [!_ctx.disabledAutomations.length ? 'mb-12' : '']])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_card_group, {
              title: _ctx.$t('pages.automations.active')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enabledAutomations, (automation) => {
                    return (_openBlock(), _createBlock(_component_card_automation, {
                      key: automation.id,
                      automation: automation
                    }, null, 8, ["automation"]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.disabledAutomations.length)
      ? (_openBlock(), _createBlock(_component_app_section, {
          key: 1,
          class: "pt-8 pb-8 mb-12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_card_group, {
              title: _ctx.$t('pages.automations.available'),
              description: _ctx.$t('pages.automations.activateText')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.disabledAutomations, (automation) => {
                    return (_openBlock(), _createBlock(_component_card_automation, {
                      key: automation.id,
                      automation: automation
                    }, null, 8, ["automation"]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["title", "description"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}