import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "font-medium" }
const _hoisted_3 = { class: "flex justify-start items-end mt-5" }
const _hoisted_4 = { class: "inline-flex justify-start items-center flex-grow mb-2" }
const _hoisted_5 = {
  key: 0,
  class: "inline-flex p-2 mr-3 bg-red-300 rounded-full bg-light-2"
}
const _hoisted_6 = { class: "flex justify-start items-end" }
const _hoisted_7 = { class: "text-6xl inline-block mr-1 font-bold" }
const _hoisted_8 = {
  key: 0,
  class: "text-2xl leading-9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-white rounded-xl p-5 shadow-lg hover:shadow-xl border border-white hover:border-light-3", [_ctx.disabled ? 'opacity-35' : '']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.header), 1),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 0,
            class: "animate-spin ml-2 h-3 w-3",
            name: "Spinner"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasIcon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _renderSlot(_ctx.$slots, "icon")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.text || _ctx.valueCalculated), 1),
          (_ctx.currency.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.currency), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.hasTrend)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["inline-flex items-center leading-3 text-white text-2xs rounded-md p-2", [_ctx.trendSign === '+' ? 'bg-success' : 'bg-danger']])
          }, [
            _createTextVNode(_toDisplayString(_ctx.trendSign) + " " + _toDisplayString(_ctx.trendCalculated) + " % ", 1),
            (_ctx.trendSign === '+')
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 0,
                  name: "TrendingUp",
                  size: "3"
                }))
              : (_openBlock(), _createBlock(_component_app_icon, {
                  key: 1,
                  name: "TrendingDown",
                  size: "3"
                }))
          ], 2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}