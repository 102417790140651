import { computed, ref, watch } from "vue";
import { KPIState } from "@/store/types";
import { useStore } from "vuex";

const useKPIs = () => {
    const store = useStore();
    const kpiDays = ref(30);

    watch(kpiDays, (val, oldVal) => {
        if (val !== oldVal) {
            store
                .dispatch("kpis/fetchKPIs", {
                    period: kpiDays.value
                })
                .finally();
        }
    });

    return {
        kpiDays,
        kpis: computed((): KPIState => store.state.kpis.data),
        kpisLoading: computed((): KPIState => store.state.kpis.loading)
    };
};

export default useKPIs;
