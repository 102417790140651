
import { defineComponent } from "vue";

export default defineComponent({
    name: "CardGroup",
    props: {
        title: { type: String, default: "" },
        description: { type: String, default: "" },
        disabled: { type: Boolean, default: false }
    }
});
