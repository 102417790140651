import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch relative inline-block w-11 h-6 outline-none translate-x-2" }
const _hoisted_2 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      checked: _ctx.active,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:active', !_ctx.active))),
      class: "w-0 h-0 opacity-0"
    }, null, 40, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(["slider rounded-full absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition duration-300", [_ctx.active ? 'bg-blue-500' : 'bg-light-2']])
    }, null, 2),
    _createElementVNode("span", {
      class: _normalizeClass(["absolute cursor-pointer rounded-full w-5 h-5 bg-white left-0.5 bottom-0.5 shadow-toggle transition duration-300", [
                _ctx.active ? 'transform translate-x-5' : 'transform translate-x-0'
            ]])
    }, null, 2)
  ]))
}