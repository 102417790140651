import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex justify-start items-center text-sm text-dark-2 font-md h-4 cursor-pointer select-none px-5 capitalize" }
const _hoisted_2 = ["checked", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
    _createElementVNode("input", {
      type: "radio",
      checked: _ctx.modelValue === _ctx.value,
      name: "radio",
      value: _ctx.value,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      class: "absolute opacity-0 cursor-pointer"
    }, null, 40, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(["absolute top-0 left-0 h-4 w-4 bg-white rounded-full", [
                _ctx.modelValue === _ctx.value
                    ? 'border-5 border-blue-500 transition duration-300'
                    : 'border border-light-3 shadow-sm'
            ]])
    }, null, 2)
  ]))
}