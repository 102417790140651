
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppRadioButton",
    props: {
        label: { type: String, default: "" },
        modelValue: { type: [String, Number] },
        value: { type: [String, Number] }
    },
    emits: ["update:modelValue"]
});
