
import { computed, defineComponent, toRef } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "CardKpi",
    components: {
        AppIcon
    },
    props: {
        header: { type: String, required: true },
        text: { type: [String, Number], default: "" },
        value: { type: Number, default: 0 },
        trend: { type: [Number, Object], default: null },
        currency: { type: [String, Number], default: "" },
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    setup(props, { slots }) {
        const hasIcon = computed(() => slots.icon !== undefined);

        const trend = toRef(props, "trend");
        const hasTrend = computed(
            (): boolean => !!trend.value && !!props.value
        );

        const trendSign = computed((): string => {
            const trendNumber = trend.value || 0;
            if (typeof trend.value === "number") {
                return props.value - Number(trendNumber) >= 0 ? "+" : "-";
            }
            return "";
        });

        const trendCalculated = computed((): string => {
            const trendNumber = trend.value || 0;
            if (trendNumber === 0 || props.value == 0) {
                return "0";
            }
            const trendPercent = Math.abs(
                ((props.value - Number(trendNumber)) / Number(trendNumber)) *
                    100
            );
            return trendPercent >= 1000
                ? (trendPercent / 1000).toFixed(1) + "k"
                : trendPercent >= 100
                ? trendPercent.toFixed(0)
                : trendPercent.toFixed(1);
        });

        const value = toRef(props, "value");

        const valueCalculated = computed((): string => {
            return Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 1
            }).format(value.value);
        });

        return {
            hasIcon,
            hasTrend,
            trendSign,
            trendCalculated,
            valueCalculated
        };
    }
});
