const useIframe = () => {
    const getDocHeight = (doc: Document) => {
        doc = doc || document;
        const body = doc.body,
            html = doc.documentElement;
        return Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
    };

    const getDocWidth = (doc: Document) => {
        doc = doc || document;
        const body = doc.body,
            html = doc.documentElement;
        return Math.max(
            body.scrollWidth,
            body.offsetWidth,
            html.clientWidth,
            html.scrollWidth,
            html.offsetWidth
        );
    };

    const setIframeSize = (iframe: HTMLIFrameElement, wrapper: HTMLElement) => {
        const doc: Document | null =
            iframe.contentDocument ||
            (iframe.contentWindow ? iframe.contentWindow.document : null);

        if (!doc) {
            return;
        }
        const width = getDocWidth(doc);
        const height = getDocHeight(doc);

        iframe.style.visibility = "hidden";
        iframe.style.height = "10px"; // reset to minimal height ...
        // IE opt. for bing/msn needs a bit added or scrollbar appears
        iframe.style.height = height + 4 + "px";
        iframe.style.width = width + 4 + "px";
        wrapper.style.height = (height + 4) / 2 + "px";
        wrapper.style.width = width / 2 + "px";
        iframe.style.visibility = "visible";
    };

    return { setIframeSize };
};

export default useIframe;
