
import { defineComponent, PropType, ref } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

const Status = {
    empty: "",
    success: "success",
    danger: "danger",
    muted: "muted"
};
enum ActionEnum {
    view = "view",
    edit = "edit"
}
export default defineComponent({
    name: "CardListItem",
    components: {
        AppIcon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        status: {
            type: String,
            default: Status.empty,
            validator: (value: string) => {
                return Object.values(Status).indexOf(value) !== -1;
            }
        },
        active: {
            type: Boolean,
            default: false
        },
        contextMenu: {
            type: Boolean,
            default: false
        },
        action: {
            type: String as PropType<ActionEnum>,
            default: "edit",
            validator: (value: ActionEnum) => {
                return Object.values(ActionEnum).includes(value);
            }
        },
        contextMenuActions: {
            type: Array,
            default: () => []
        },
        actionDisabled: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showActions: {
            type: Boolean,
            default: true
        }
    },
    emits: ["edit", "view"],
    setup(props, { emit }) {
        const contextMenuOpen = ref(false);

        const toggleContextMenu = () => {
            contextMenuOpen.value = !contextMenuOpen.value;
        };

        const emitAction = (action: ActionEnum) => {
            if (props.actionDisabled || props.disabled) {
                return;
            }
            emit(action);
        };

        return {
            Status,
            emitAction,
            contextMenuOpen,
            toggleContextMenu
        };
    }
});
