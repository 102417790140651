import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center mb-2" }
const _hoisted_2 = {
  key: 1,
  class: "hidden md:block h-8 leading-8"
}
const _hoisted_3 = {
  key: 0,
  class: "text-sm mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h3", {
            key: 0,
            class: _normalizeClass(["leading-8", [_ctx.disabled ? 'opacity-50' : '']])
          }, _toDisplayString(_ctx.title), 3))
        : (_openBlock(), _createElementBlock("h3", _hoisted_2)),
      _renderSlot(_ctx.$slots, "sorting")
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}